import { rgba } from 'polished';

const PRIMARY = '#526fd3';
const DANGER = '#ff1818';
/**
 * This is common variable for styling.
 */

export const COLOR = {
  PRIMARY: PRIMARY,
  PRIMARY_LIGHT: rgba(PRIMARY, 0.1),
  DANGER: DANGER,
  DANGER_LIGHT: rgba(DANGER, 0.1),
  BODY_BACKGROUND: '#f5f5f7',
  MODAL_BACKGROUND: '#fff',
  MODAL_BODY_BACKGROUND: 'rgba(255, 255, 255, 0.85)',
  TABLE_BACKGROUND: '#fff',
  TABLE_HEADER_BACKGROUND: '#dde7fc',
  TABLE_TH_HOVER_BACKGROUND: '#eef2f4',
  TABLE_TR_BACKGROUND: '#fff',
  TABLE_TR_HOVER_BACKGROUND: '#edf0fa',
  TABLE_ICON: 'rgba(0, 0, 0, 0.1)',
  TABLE_ICON_ACTIVE: PRIMARY,
  TABLE_ICON_HOVER: 'rgba(0, 0, 0, 0.5)',
  TABLE_ICON_HOVER_BACKGROUND: 'rgba(0, 0, 0, 0.05)',
  TABLE_BORDER_COLOR: 'rgba(0, 0, 0, 0.1)',
  TABLE_SHADOW: 'rgba(0, 0, 0, 0.05)',
  TABLE_TH_SORTED_BACKGROUND: '#bdcffa',
  TABLE_TD_SORTED_BACKGROUND: '#f5f5f7',
  HEADER_PRIMARY_TEXT: '#fff',
  HEADER_SECONDARY_TEXT: 'rgba(255, 255, 255, 0.5)',
  HEADER_BACKGROUND: '#313b5a',
  MENU_SHADOW: 'rgba(0, 0, 0, 0.2)',
  MENU_ITEM: '#59739c',
  TITLE: 'rgba(0, 0, 0, 0.7)',
  FORM_LABEL_TEXT: 'rgba(0, 0, 0, 0.7)',
  NORMAL_ICON: 'rgba(0, 0, 0, 0.5)',
  INPUT_ICON: 'rgba(0, 0, 0, 0.5)',
  INPUT_DISABLED_ICON: 'rgba(0, 0, 0, 0.2)',
  INPUT_BORDER: '#d9d9d9',
  INPUT_DISABLED_BACKGROUND: '#f5f5f5',
  BORDER: 'rgba(0, 0, 0, 0.05)',
  PLACEHOLDER: '#d9dfe8',
  LINEAR_GRADIENT_PLACEHOLDER: '#ccd4e0',
  HINT_TEXT: 'rgba(0, 0, 0, 0.7)',
  ACTION_ICON: 'rgba(0, 0, 0, 0.5)',
  ACTION_ICON_ACTIVE: PRIMARY,
  CHECKBOX_DISABLED_BACKGROUND: '#e8e8e8',
  CHECKBOX_DISABLED_BORDER: 'rgba(0, 0, 0, 0.2)',
};
