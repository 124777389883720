export const TEXT = 'text';
export const INTEGER = 'integer';
export const BOOLEAN = 'boolean';
export const JSONB = 'jsonb';
export const JSONC = 'json';
export const DATE = 'date';
export const TIME = 'time with time zone';
export const DATE_TIME = 'timestamp with time zone';
export const SELECT = '(client) select';
export const FILE = '(client) file';
export const HTML = '(client) html';
export const NUMERIC = 'numeric';
export const NUMBER_THOUSAND_SEPARATOR = 'numberthousandseparator';
export const MULTIPLE_SELECT = '(client) mutiple select';
export const XML = 'xml';
export const ARRAY = 'ARRAY';
export const ENUM = '(client) enum select';
export const UUID = 'uuid';
