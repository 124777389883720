import gql from 'graphql-tag';

export const GET_USER_INFO = gql`
  query {
    user: me {
      id
      name
      email
      phone
      avatar {
        id
        link
      }
    }
  }
`;

export const GET_USER_BY_ID = gql`
  query GetUserById($id: uuid!) {
    user: users_by_pk(id: $id) {
      id
      name
      email
      phone
      avatar {
        id
        link
      }
    }
  }
`;

export const GET_USER_EMAIL_BY_ID = gql`
  query GetUserById($id: uuid!) {
    user: users_by_pk(id: $id) {
      id
      email
    }
  }
`;

export const UPDATE_USER_INFO = gql`
  mutation UpdateUserInfo($input: UpdateUserInput!) {
    update_user_info(user: $input) {
      id
      name
      email
      phone
      avatar {
        id
        link
      }
    }
  }
`;

// trick here, should always be empty response
export const UPDATE_USER_PASSWORD = gql`
  mutation UpdateUserPassword($oldPassword: String!, $newPassword: String!) {
    update_password(oldPassword: $oldPassword, newPassword: $newPassword)
  }
`;

export const LOGIN = gql`
  mutation Login($email: String!, $password: String!, $organizationId: String) {
    login(email: $email, password: $password, organizationId: $organizationId) {
      token
    }
  }
`;

export const SIGNUP = gql`
  mutation Signup($name: String!, $email: String!, $password: String!) {
    register(name: $name, email: $email, password: $password) {
      token
    }
  }
`;

export const FORGET_PASSWORD = gql`
  mutation ForgetPassword($email: String!) {
    forget_password(email: $email)
  }
`;

export const CHECK_RESET_PASSWORD_TOKEN = gql`
  mutation CheckResetPasswordToken($token: String!) {
    check_reset_password_token_status(token: $token)
  }
`;

export const RESET_PASSWORD = gql`
  mutation ResetPassword($token: String!, $newPassword: String!) {
    reset_password(password: $newPassword, token: $token)
  }
`;
