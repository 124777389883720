import React from 'react';
import { Machine, assign } from 'xstate';
import { client } from 'apollo/client';
import { GET_USER_INFO } from 'apollo/graphqls/user';
import { AUTH_TOKEN } from 'constants/index';
import { useMachine, useService } from '@xstate/react';
import { history } from 'history/history';
import { loginMachine } from './loginMachine';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';

const S = {
  UKNOWN: 'UKNOWN',
  UNLOGIN: 'UNLOGIN',
  LOGINING: 'LOGINING',
  LOADING_USERINFO: 'LOADING_USERINFO',
  WATCH_USERINFO: 'WATCH_USERINFO',
  LOADED: 'LOADED',
  FAILURE: 'FAILURE',
};

const E = {
  SIGN_IN: 'SIGN_IN',
  SIGN_OUT: 'SIGN_OUT',
  UPDATE_USERINFO: 'UPDATE_USERINFO',
};

const userMachine = Machine(
  {
    context: { user: {} },
    initial: S.UKNOWN,
    states: {
      [S.UKNOWN]: {
        on: {
          '': [
            { cond: 'isLogin', target: S.LOADING_USERINFO, actions: ['redirectToDashboard'] },
            { target: S.UNLOGIN },
          ],
        },
      },
      [S.UNLOGIN]: {
        id: S.UNLOGIN,
        invoke: {
          id: 'login',
          src: 'loginMachine',
          onDone: { target: S.LOADING_USERINFO },
        },
      },
      [S.LOADING_USERINFO]: {
        invoke: {
          src: 'getUserInfo',
          onDone: { target: S.LOADED, actions: ['assignUserInfo'] },
          onError: { target: S.UNLOGIN, actions: ['removeToken', 'redirectLogin'] },
        },
      },
      [S.LOADED]: {
        type: 'parallel',
        states: {
          [S.WATCH_USERINFO]: {
            invoke: {
              src: 'watchUserInfo',
              onError: { target: `#${S.UNLOGIN}`, actions: ['removeToken', 'redirectLogin'] },
            },
            on: {
              [E.UPDATE_USERINFO]: { actions: ['assignUserInfo'] },
            },
          },
        },
        on: {
          [E.SIGN_OUT]: { target: S.UNLOGIN, actions: ['removeToken', 'logoutRedirect'] },
        },
      },
    },
  },
  {
    guards: {
      isLogin: () => !!window.localStorage.getItem(AUTH_TOKEN),
    },
    services: {
      loginMachine: () => loginMachine,
      getUserInfo: () => client.query({ query: GET_USER_INFO }),
      watchUserInfo: () =>
        from(client.watchQuery({ query: GET_USER_INFO })).pipe(
          map(res => ({ type: E.UPDATE_USERINFO, data: res }))
        ),
    },
    actions: {
      redirectToDashboard: () => {
        if (window.location.pathname.startsWith('/user')) {
          history.push('/dashboard');
        }
      },
      assignUserInfo: assign({ user: (context, event) => event.data.data.user }),
      removeToken: () => window.localStorage.removeItem(AUTH_TOKEN),
      redirectLogin: () => history.push({ pathname: '/user/login', state: window.location }),
      logoutRedirect: () => window.location.assign('/user/login'),
    },
  }
);

const UserContext = React.createContext(null);
const UserProvider = ({ children }) => {
  const [, , service] = useMachine(userMachine);
  return <UserContext.Provider value={service}>{children}</UserContext.Provider>;
};
const useUserService = () => {
  const service = React.useContext(UserContext);
  return useService(service);
};

export { UserProvider, useUserService, userMachine, S as USER_STATE, E as USER_EVENT };
