import * as React from 'react';
import styled from 'styled-components';
import { COLOR } from 'views/styleVariable';
import ErrorIcon from 'images/icon-error-web-2.svg';

const Background = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  height: 100%;
  background-color: ${COLOR.BODY_BACKGROUND};
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 200px;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const LogoWrapper = styled.div`
  width: 160px;
  height: 160px;
`;

const Title = styled.h1`
  margin-top: 16px;
  font-size: 36px;
  font-weight: 600;
  line-height: normal;
  color: #0084ff;
`;

const Subtitle = styled.span`
  font-size: 14px;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.7);
  margin: 8px 0 24px 0;
`;

const ErrorPage = ({ errorCode = '500', errorMessage = 'Internal Server Error.' }) => {
  return (
    <Background>
      <Wrapper>
        <LogoWrapper>
          <img src={ErrorIcon} alt="" />
        </LogoWrapper>
        <Title>{errorCode} Error</Title>
        <Subtitle>{errorMessage}</Subtitle>
      </Wrapper>
    </Background>
  );
};

export default ErrorPage;
