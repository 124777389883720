import * as R from 'ramda';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import { AUTH_TOKEN } from '../constants';
import { HASURA_API_URL, HASURA_WEBSOCKET_URL } from '../constants/endpoints';
import { postToGTM } from 'utils/gtm';
import { GRAPHQL_ERROR, NETWORK_ERROR } from 'constants/gtmEventTypes';
import { WebSocketLink } from 'apollo-link-ws';
import { split } from 'apollo-link';
import { getMainDefinition } from 'apollo-utilities';
import { client } from './client';
import { GET_USER_INFO } from './graphqls/user';

export const errorLink = onError(({ networkError, graphQLErrors }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ extensions, message, ...rest }) => {
      const msg = message
        ? `[GraphQL error] ${message}`
        : `[GraphQL error] ${extensions.code}: ${extensions.error}`;
      postToGTM({
        event: GRAPHQL_ERROR,
        error: new Error(msg),
      });
      console.warn(msg, rest);

      // Check authorized
      if (localStorage.getItem(AUTH_TOKEN)) {
        client.query({ query: GET_USER_INFO, fetchPolicy: 'no-cache' }).catch(() => {
          localStorage.removeItem(AUTH_TOKEN);
          window.location.assign('/user/login');
        });
      }
    });
  }

  if (networkError) {
    console.warn(`[Network error]:`, networkError);
    postToGTM({
      event: NETWORK_ERROR,
      error: networkError,
    });
  }
});

export const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem(AUTH_TOKEN);

  return {
    headers: R.cond([
      [R.always(token), R.assoc('authorization', `Bearer ${token}`)],
      [R.T, R.identity],
    ])(headers),
  };
});

const httpLink = createHttpLink({
  uri: `${HASURA_API_URL}/v1alpha1/graphql`,
  credentials: 'include',
});

const wsLink = new WebSocketLink({
  uri: `${HASURA_WEBSOCKET_URL}/v1alpha1/graphql`,
  options: {
    reconnect: true,
    connectionParams: {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
      },
    },
  },
});

export const link = split(
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return kind === 'OperationDefinition' && operation === 'subscription';
  },
  wsLink,
  httpLink
);
