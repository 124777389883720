import { Machine, assign } from 'xstate';
import * as R from 'ramda';
import { history } from 'history/history';
import { AUTH_TOKEN } from 'constants/index';
import { client } from 'apollo/client';
import {
  LOGIN,
  SIGNUP,
  FORGET_PASSWORD,
  RESET_PASSWORD,
  CHECK_RESET_PASSWORD_TOKEN,
  GET_USER_EMAIL_BY_ID,
} from 'apollo/graphqls/user';
import { getGraphqlErrorMessages } from 'utils/getGraphqlError';
import notification from 'utils/notification';
import { TOKEN_EXPIRED_ERROR } from 'utils/graphqlErrorCode';

const S = {
  IDLE: 'IDLE',
  UNKNOWN: 'UNKNOWN',
  LOGIN_PAGE: 'LOGIN_PAGE',
  LOGINING: 'LOGINING',
  SIGNUP_PAGE: 'SIGNUP_PAGE',
  SINGINGUP: 'SINGINGUP',
  FORGET_PADDWORD_PAGE: 'FORGET_PADDWORD_PAGE',
  RESET_PASSWORD_PAGE: 'RESET_PASSWORD_PAGE',
  CHECKING_TOKEN: 'CHECKING_TOKEN',
  WAIT_CHECKING: 'WAIT_CHECKING',
  SENDING_FORGET_PASSWORD_EMAIL: 'SENDING_FORGET_PASSWORD_EMAIL',
  SUCCESS: 'SUCCESS',
  PASSWORD_RESETING: 'PASSWORD_RESETING',
};

const E = {
  LOGIN: 'LOGIN',
  SIGNUP: 'SIGNUP',
  GO_LOGIN: 'GO_LOGIN',
  GO_FORGET_PASSWORD: 'GO_FORGET_PASSWORD',
  SEND_RESET_PASSWORD_EMAIL: 'SEND_RESET_PASSWORD_EMAIL',
  RESET_PASSWORD: 'RESET_PASSWORD',
  UPDATE_LOGIN_REDIRECT: 'UPDATE_LOGIN_REDIRECT',
  CHECK_RESET_PASSWORD_TOKEN: 'CHECK_RESET_PASSWORD_TOKEN',
  GET_USER_EMAIL_BY_ID: 'GET_USER_EMAIL_BY_ID',
  CHANGE_URL: 'CHANGE_URL',
};

const loginMachine = Machine(
  {
    initial: S.UNKNOWN,
    context: { loginRedirectUrl: '' },
    on: {
      [E.CHANGE_URL]: S.UNKNOWN,
    },
    states: {
      [S.UNKNOWN]: {
        on: {
          '': [
            { target: S.SIGNUP_PAGE, cond: 'isSignupPath' },
            { target: S.FORGET_PADDWORD_PAGE, cond: 'isForgetPasswordPath' },
            { target: S.RESET_PASSWORD_PAGE, cond: 'isResetPasswordPath' },
            { target: S.LOGIN_PAGE },
          ],
        },
      },
      [S.LOGIN_PAGE]: {
        id: S.LOGIN_PAGE,
        initial: S.IDLE,
        states: {
          [S.IDLE]: {
            on: {
              [E.LOGIN]: S.LOGINING,
              [E.GO_FORGET_PASSWORD]: `#${S.FORGET_PADDWORD_PAGE}`,
              [E.UPDATE_LOGIN_REDIRECT]: { actions: 'assignLoginRedirectUrl' },
            },
          },
          [S.LOGINING]: {
            invoke: {
              src: 'login',
              onDone: { target: `#${S.SUCCESS}`, actions: ['setToken', 'loginRedirect'] },
              onError: { target: S.IDLE, actions: ['notifyFailure'] },
            },
          },
        },
      },
      [S.SIGNUP_PAGE]: {
        initial: S.WAIT_CHECKING,
        states: {
          [S.WAIT_CHECKING]: {
            on: {
              [E.GET_USER_EMAIL_BY_ID]: { target: S.CHECKING_TOKEN, actions: ['assignUserId'] },
            },
          },
          [S.CHECKING_TOKEN]: {
            invoke: {
              src: 'getUserEmailById',
              onDone: { target: S.IDLE, actions: ['assignEmail'] },
              onError: { target: `#${S.LOGIN_PAGE}`, actions: ['redirectToLogin'] },
            },
          },
          [S.IDLE]: {
            on: {
              [E.GO_LOGIN]: `#${S.LOGIN_PAGE}`,
              [E.SIGNUP]: S.SINGINGUP,
            },
          },
          [S.SINGINGUP]: {
            invoke: {
              src: 'signup',
              onDone: { target: `#${S.SUCCESS}`, actions: ['setToken', 'signUpRedirect'] },
              onError: { target: S.IDLE, actions: ['notifyFailure'] },
            },
          },
        },
      },
      [S.FORGET_PADDWORD_PAGE]: {
        id: S.FORGET_PADDWORD_PAGE,
        initial: S.IDLE,
        states: {
          [S.IDLE]: {
            on: {
              [E.GO_LOGIN]: `#${S.LOGIN_PAGE}`,
              [E.SEND_RESET_PASSWORD_EMAIL]: S.SENDING_FORGET_PASSWORD_EMAIL,
            },
          },
          [S.SENDING_FORGET_PASSWORD_EMAIL]: {
            invoke: {
              src: 'forgetPassword',
              onDone: { target: `#${S.LOGIN_PAGE}`, actions: ['notifySendEmailSuccess', 'redirectToLogin'] },
              onError: { target: S.IDLE, actions: ['notifyFailure'] },
            },
          },
        },
      },
      [S.RESET_PASSWORD_PAGE]: {
        initial: S.WAIT_CHECKING,
        states: {
          [S.WAIT_CHECKING]: {
            on: {
              [E.CHECK_RESET_PASSWORD_TOKEN]: S.CHECKING_TOKEN,
            },
          },
          [S.CHECKING_TOKEN]: {
            invoke: {
              src: 'checkResetPasswordToken',
              onDone: { target: S.IDLE },
              onError: { target: `#${S.LOGIN_PAGE}`, actions: ['notifyTokenExpired', 'redirectToLogin'] },
            },
          },
          [S.IDLE]: {
            on: {
              [E.RESET_PASSWORD]: S.PASSWORD_RESETING,
            },
          },
          [S.PASSWORD_RESETING]: {
            invoke: {
              src: 'resetPassword',
              onDone: {
                target: `#${S.LOGIN_PAGE}`,
                actions: ['notifyResetPasswordSuccess', 'redirectToLogin'],
              },
              onError: { target: S.IDLE, actions: ['notifyFailure'] },
            },
          },
        },
      },
      [S.SUCCESS]: {
        id: S.SUCCESS,
        type: 'final',
      },
    },
  },
  {
    guards: {
      isSignupPath: () =>
        R.pipe(R.pathOr('', ['location', 'pathname']), R.startsWith('/user/signup'))(history),
      isForgetPasswordPath: () => R.pathEq(['location', 'pathname'], '/user/forgetPassword', history),
      isResetPasswordPath: () => R.pathEq(['location', 'pathname'], '/user/resetPassword', history),
    },
    services: {
      login: (_, { email, password, orgId }) =>
        client
          .mutate({ mutation: LOGIN, variables: { email, password, orgId } })
          .then(res => res.data.login.token),
      signup: (_, { email, password, orgId, name }) =>
        client
          .mutate({
            mutation: SIGNUP,
            variables: { email, name, password, orgId },
          })
          .then(res => res.data.register.token),
      forgetPassword: (_, { email }) => client.mutate({ mutation: FORGET_PASSWORD, variables: { email } }),
      resetPassword: (_, { newPassword, token }) =>
        client.mutate({ mutation: RESET_PASSWORD, variables: { newPassword, token } }),
      checkResetPasswordToken: (_, { token }) =>
        client.mutate({ mutation: CHECK_RESET_PASSWORD_TOKEN, variables: { token } }),
      getUserEmailById: ({ userId }) =>
        client.query({ query: GET_USER_EMAIL_BY_ID, variables: { id: userId } }).then(res => res.data.user),
    },
    actions: {
      notifyTokenExpired: (_, event) => {
        if (R.pathEq(['graphQLErrors', 0, 'extensions', 'code'], TOKEN_EXPIRED_ERROR, event.data)) {
          notification.error({ message: 'Link expired after 24 hours' });
        }
      },
      notifyResetPasswordSuccess: () =>
        notification.success({ message: 'Your Cowsquare password reset was successful.' }),
      redirectToLogin: () => history.push('/user/login'),
      assignLoginRedirectUrl: assign({ loginRedirectUrl: (_, event) => event.redirectUrl }),
      assignEmail: assign({ email: (_, event) => event.data.email }),
      assignUserId: assign({ userId: (_, event) => event.userId }),
      setToken: (_, event) => window.localStorage.setItem(AUTH_TOKEN, event.data),
      loginRedirect: context =>
        context.loginRedirectUrl.startsWith('/')
          ? history.replace(context.loginRedirectUrl)
          : window.location.assign(context.loginRedirectUrl),
      signUpRedirect: () => history.replace('/dashboard'),
      notifySendEmailSuccess: () =>
        notification.success({ message: 'Please check your email for the reset password link.' }),
      notifyFailure: (_, event) =>
        getGraphqlErrorMessages(event.data).forEach(message => notification.error({ message })),
    },
  }
);

export { loginMachine, S as LOGIN_STATE, E as LOGIN_EVENT };
