import React from 'react';
import { postToGTM } from 'utils/gtm';
import { COMPONENT_CATCH_ERROR } from 'constants/gtmEventTypes';
import ErrorPage from 'views/pages/ErrorPage';

class ErrorBoundary extends React.Component {
  state = { hasError: false };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    postToGTM({
      event: COMPONENT_CATCH_ERROR,
      error: error,
      componentErrorInfo: info,
    });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return this.props.errorRender || <ErrorPage />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
