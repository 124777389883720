import React from 'react';
import { notification } from 'antd';
import Icon, {
  InfoCircleOutlined,
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';
// eslint-disable-next-line no-unused-vars
import { ArgsProps } from 'antd/lib/notification';
import { CloseIcon } from 'views/components/Icons';

const defaultProps = () => ({
  className: 'custom-notification',
  closeIcon: <Icon component={CloseIcon} />,
});

/**
 * Independent notification code.
 * If we change 3rd library in the future, then we just need to modify this file.
 */

export default {
  /**
   * @param {ArgsProps} config
   * @return {void}
   */
  info: ({ duration = 3, ...restProps }) =>
    notification.info({
      duration,
      icon: <InfoCircleOutlined style={{ color: '#1890ff' }} />,
      ...defaultProps(),
      ...restProps,
    }),
  /**
   * @param {ArgsProps} config
   * @return {void}
   */
  success: ({ duration = 3, ...restProps }) =>
    notification.success({
      duration,
      icon: <CheckCircleOutlined style={{ color: '#52c41a' }} />,
      ...defaultProps(),
      ...restProps,
    }),
  /**
   * @param {ArgsProps} config
   * @return {void}
   */
  warn: ({ duration = 3, ...restProps }) =>
    notification.warn({
      duration,
      icon: <ExclamationCircleOutlined style={{ color: '#faad14' }} />,
      ...defaultProps(),
      ...restProps,
    }),
  /**
   * @param {ArgsProps} config
   * @return {void}
   */
  error: ({ duration = 5, ...restProps }) =>
    notification.error({
      duration,
      icon: <CloseCircleOutlined style={{ color: '#ff4d4f' }} />,
      ...defaultProps(),
      ...restProps,
    }),
  close: notification.close,
};
