import * as React from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import loadable from '@loadable/component';
import PrivateRoute from './components/PrivateRoute';
import ErrorBoundary from './components/ErrorBoundary';
import { history } from 'history/history';
import { UserProvider } from 'machines/userMachine';

const UserLayout = loadable(() => import('./layouts/UserLayout'));
const DashboardLayout = loadable(() => import('./layouts/DashboardLayout'));
const ErrorPage = loadable(() => import('./pages/ErrorPage'));

const App = () => {
  return (
    <Router history={history}>
      <ErrorBoundary>
        <UserProvider>
          <Switch>
            <Redirect exact from="/" to="/dashboard" />
            <Route path="/user" component={UserLayout} />
            <PrivateRoute path="/dashboard" component={DashboardLayout} />
            <Route component={() => <ErrorPage errorCode="404" errorMessage="Page not found." />} />
          </Switch>
        </UserProvider>
      </ErrorBoundary>
    </Router>
  );
};

export default App;
